.scrolling {
  animation: up 5s ease-in-out infinite;
  transition: transform 500ms;
}
@keyframes up {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(20%);
  }
}

.lazy {
  background-position: center;
  background-size: cover;
  position: relative;
}
.lazy::before {
  content: "";
  position: absolute;
  animation: blur 2.5s infinite;
  inset: 0;
}
.lazy.loaded > img {
  opacity: 1;
}
.lazy.loaded::before {
  content: none;
}
.lazy:has(video)::before {
  content: none;
}
.lazy.loaded {
  background-image: none;
}
.lazy > img .lazy > video {
  opacity: 0;
  transition: opacity ease 300ms;
}

@keyframes blur {
  0% {
    background-color: rgba(0, 0, 0, 0.1);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.5);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.animate-bg {
  animation: changeBg 2s linear infinite;
  transition: all 200ms;
}

@keyframes changeBg {
  0% {
    background-color: orangered;
  }
  25% {
    background-color: green;
  }
  50% {
    background-color: red;
  }
  75% {
    background-color: yellow;
  }
  100% {
    background-color: blue;
  }
}
